import React from "react";
import { motion } from "framer-motion";

import "../../container/Blogs/Blogs.scss";

const Blog = (props) => {
  return (
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__blogs-item app__flex"
      key={props.name}
    >
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        className={`${props.name} - ${props.author} - ${props.date}`}
        style={{ textDecoration: "none" }}
      >
        <h4 className="bold-text">{props.name}</h4>

        <p className="p-text blog-content">{props.content}</p>
      </a>
    </motion.div>
  );
};

export default Blog;
