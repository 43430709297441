import React from "react";
import { motion } from "framer-motion";
import { AiFillEye, AiFillGithub } from "react-icons/ai";

import "../../container/Work/Work.scss";

const WorkComponent = (props) => {
  var projectBool = props.projectBool;
  var codeBool = props.codeBool;

  return (
    <div className="app__work-item app__flex" key={props.keyIndex}>
      <div className="app__work-img app__flex">
        <img src={props.img} alt={props.name}></img>
        <motion.div
          whileHover={{ opacity: [0, 1] }}
          transition={{
            duration: 0.25,
            ease: "easeInOut",
            staggerChildren: 0,
          }}
          className="app__work-hover app__flex"
        >
          {projectBool && (
            <a href={props.projectLink} target="_blank" rel="noreferrer">
              <motion.div
                whileInView={{ scale: [0, 1] }}
                whileHover={{ scale: [1, 0.9] }}
                transition={{
                  duration: 0.25,
                }}
                className="app__flex"
              >
                <AiFillEye />
              </motion.div>
            </a>
          )}
          {codeBool && (
            <a href={props.codeLink} target="_blank" rel="noreferrer">
              <motion.div
                whileInView={{ scale: [0, 1] }}
                whileHover={{ scale: [1, 0.9] }}
                transition={{
                  duration: 0.25,
                }}
                className="app__flex"
              >
                <AiFillGithub />
              </motion.div>
            </a>
          )}
        </motion.div>
      </div>
      <div className="app__work-content app__flex">
        <h4 className="bold-text">{props.name}</h4>
        <p className="p-text" style={{ marginTop: 10 }}>
          {props.content}
        </p>

        <div className="app__work-tag app__flex">
          <p className="p-text">{props.type}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkComponent;
