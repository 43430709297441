import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Work.scss";
import { images } from "../../constants";
import WorkComponent from "../../components/Work/WorkComponent";

const myWork = [
  {
    img: images.addGoals,
    projectLink: "#",
    projectBool: false,
    codeLink: "#",
    codeBool: false,
    name: "Course Goal App",
    content:
      "Programmed a mobile application allowing users to track theirdaily goals. Modeled an overlay to add or delete new or existing goals.",
    type: "Mobile App",
    tags: ["Mobile App", "All"],
  },
  {
    img: images.guessTheNumber,
    projectLink: "#",
    projectBool: false,
    codeLink: "#",
    codeBool: false,
    name: "Guess The Number",
    content:
      "Developerd an mobile application game which makes the user guess the number. Modeled the overlay to showcase the new number guessed",
    type: "Mobile App",
    tags: ["Mobile App", "All"],
  },
  {
    img: images.boulderGame,
    projectLink: "#",
    projectBool: false,
    codeLink: "https://github.com/ArnavMahajan01/Boulder-Dodging-Game",
    codeBool: true,
    name: "Boulder Doging Game",
    content:
      "The goal of this game is that the human character which is controlled by the player has to find its endpoint on the other side of the road and have to dodge various obstacles along the road. The player has to go through 2 levels in the game.",
    type: "Unity",
    tags: ["Unity", "All"],
  },
  {
    img: images.forum,
    projectLink: "#",
    projectBool: false,
    codeLink: "https://github.com/ArnavMahajan01/Forum",
    codeBool: true,
    name: "Forum Web Application",
    content:
      "Programmed a forum-based website on Node js. Maintained the user ability to view other user’s query and reply on them.",
    type: "Node Js",
    tags: ["Node Js", "Web App", "All"],
  },
  {
    img: images.eVoting,
    projectLink: "#",
    projectBool: false,
    codeLink: "https://github.com/ArnavMahajan01/e_voting",
    codeBool: true,
    name: "E-Voting System",
    content:
      "Devised an e-voting application in Node.js. Integrated fully functional platform with MySQL to achieve admin-controlled poll definition, poll result and robust authentication.",
    type: "Node Js",
    tags: ["Node Js", "Web App", "All"],
  },
  {
    img: images.medChain,
    projectLink: "#",
    projectBool: false,
    codeLink: "https://github.com/ArnavMahajan01/Med-Chain",
    codeBool: true,
    name: "Med Chain",
    content:
      "A mullti holistic dashboard for hospital use during Coivd. Applied predictive modeling methods to find out future Covid cases in each country, city and borough. Worked with geographically dispersed team.",
    type: "Node Js",
    tags: ["Node Js", "Web App", "All"],
  },
  {
    img: images.ordermanagementSystem,
    projectLink: "#",
    projectBool: false,
    codeLink: "#",
    codeBool: false,
    name: "Order Management System",
    content:
      "An order management process where multiple order can be created against each sales order as per available quantity in the inventory. Order status is needed to be main- tained to keep the order workflow and order management is processed in two step process.",
    type: "Node Js",
    tags: ["Node Js", "Web App", "All"],
  },
  {
    img: images.inventorymanagementSystem,
    projectLink: "#",
    projectBool: false,
    codeLink: "#",
    codeBool: false,
    name: "Inventory Management System",
    content:
      "Managed the life cycle of products, Inventory details, purchasing, manage Supplier and Customer. The need is to manage all the information about Inventory, receiving stocks and handling the transactions",
    type: "Node Js",
    tags: ["Node Js", "Web App", "All"],
  },
  {
    img: images.posAPI,
    projectLink: "https://pos-api-dh.herokuapp.com/",
    projectBool: true,
    codeLink: "https://github.com/ArnavMahajan01/POS-API-1",
    codeBool: true,
    name: "Pegasas Payment Gateway",
    content:
      "Invented a Point-of-Sale (POS) for E-commerce Applications. Integrated a secure gateway accepting payments via cryptocurrency.",
    type: "Blockchain",
    tags: ["Blockchain", "Node Js", "Web App", "All"],
  },
  {
    img: images.portfolioPage,
    projectLink: "http://www.arnav-mahajan.com/",
    projectBool: true,
    codeLink: "#",
    codeBool: false,
    name: "Portfolio Page",
    content: "My Personal Portfolio Page.",
    type: "React Js",
    tags: ["React Js", "All"],
  },
];

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterWork, setFilterWork] = useState([]);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);
    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
      if (item === "All") {
        setFilterWork(myWork);
      } else {
        setFilterWork(myWork.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  useEffect(() => {
    setFilterWork(myWork);
  }, []);

  /*  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []); */

  return (
    <>
      <h2 className="head-text">
        My <span>Portfolio</span> Section
      </h2>

      <div className="app__work-filter">
        {[
          "Blockchain",
          "Web App",
          "Node Js",
          "Mobile App",
          "Unity",
          "React Js",
          "All",
        ].map((item, index) => (
          // eslint-disable-next-line
          <a
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </a>
        ))}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <WorkComponent
            keyIndex={index}
            img={work.img}
            projectLink={work.projectLink}
            projectBool={work.projectBool}
            codeLink={work.codeLink}
            codeBool={work.codeBool}
            name={work.name}
            content={work.content}
            type={work.type}
            tags={work.tags}
          />
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
