import React from "react";
import { motion } from "framer-motion";

import SkillComponent from "../../components/Skill/SkillComponent";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Skills.scss";
import { images } from "../../constants";

const Skills = () => {
  /*   const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const querySkills = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setExperience(data);
    });

    client.fetch(querySkills).then((data) => {
      setSkills(data);
    });
  }, []); */

  return (
    <>
      <h2 className="head-text">
        <span>Skills</span> and <span>Experience</span>
      </h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {/*           {skills.map((skill) => ( */}

          <SkillComponent name={"HTML"} img={images.html} />
          <SkillComponent name={"CSS"} img={images.css} />
          <SkillComponent name={"SASS"} img={images.sass} />
          <SkillComponent name={"Bootstrap"} img={images.bootstrap} />
          <SkillComponent name={"Javascript"} img={images.javascript} />
          <SkillComponent name={"React"} img={images.react} />
          <SkillComponent name={"React Native"} img={images.reactNative} />
          <SkillComponent name={"Node"} img={images.node} />
          <SkillComponent name={"MySQL"} img={images.mySQL} />
          <SkillComponent name={"MongoDB"} img={images.mongoDB} />
          <SkillComponent name={"Solidity"} img={images.solidity} />
          <SkillComponent name={"Git"} img={images.git} />
          <SkillComponent name={"Python"} img={images.python} />
          <SkillComponent name={"C++"} img={images.cpp} />
          <SkillComponent name={"Unity"} img={images.unity} />
          {/*  ))} */}
        </motion.div>

        {/* New Div */}
        <motion.div className="app__skills-exp">
          <motion.div className="app__skills-exp-item" key="2022">
            <div className="app__skills-exp-year">
              <p className="bold-text">2022</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Node Js Developer Intern - Advalue - 2022"
                >
                  <h4 className="bold-text">Node Js Developer Intern</h4>
                  <p className="p-text">AdValue</p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>

          <motion.div className="app__skills-exp-item" key="2021">
            <div className="app__skills-exp-year">
              <p className="bold-text">2021</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Node Js Developer Intern - AdValue - 2021"
                >
                  <h4 className="bold-text">Node Js Developer Intern</h4>
                  <p className="p-text">AdValue</p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>

          <motion.div className="app__skills-exp-item" key="2020">
            <div className="app__skills-exp-year">
              <p className="bold-text">2020</p>
            </div>
            <motion.div className="app__skills-exp-works">
              <>
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Unity Developer Intern - HighAvenue - 2020"
                >
                  <h4 className="bold-text">Unity Developer Intern</h4>
                  <p className="p-text">HighAvenue</p>
                </motion.div>

                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__skills-exp-work"
                  key="Unity Developer Intern Visual Respiration - 2020"
                >
                  <h4 className="bold-text">Unity Developer Intern</h4>
                  <p className="p-text">Visual Respiration</p>
                </motion.div>
              </>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
