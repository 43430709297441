import React from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Blogs.scss";
import { images } from "../../constants";
import Blog from "../../components/Blog/Blog";

const Blogs = () => {
  return (
    <>
      <h2 className="head-text">
        My Personal <span>Blogs</span>
      </h2>
      <div className="app__blogs-container">
        <motion.div
          className="app__blog-list"
          transition={{ duration: 0.5, delayChildren: 0.5 }}
        >
          {/* {blogs.map((blog) => ( */}
          <Blog
            name={"Diabetic Retinopathy"}
            author={"Arnav Mahajan"}
            date={"Apr 2021"}
            content={
              "Recently, Diabetic Retinopathy(DR) is on the rise as a disease.It is affecting the eye of the diabetic patients because of the drastic increase in the blood sugar level. About 1.5 million people died directly from diabetes in 2019."
            }
            link={images.diabeticRetinopathy}
          />

          <Blog
            name={"CRUD Operations"}
            author={"Arnav Mahajan"}
            date={"Jun 2022"}
            content={
              "Within the computer programming world, CRUD is an elision for the 4 operations Create, Read, Update and Delete. Create, Read, Update, and  Delete (CRUD) are the four basic functions that models should be able to do, at most. In RESTful applications, CRUD often corresponds to the HTTP methods like POST, PUT, DELETE, and GET respectively."
            }
            link={
              "https://www.geeksforgeeks.org/crud-operations-and-file-upload-using-node-js-and-mongodb/"
            }
          />

          <Blog
            name={"JOI Validation"}
            author={"Arnav Mahajan"}
            date={"Aug 2022"}
            content={
              "Over the years the JavaScript has evolved in a very short time from callbacks to promises and then to async/await. The same goes for the packages. When a request comes in, we want to make sure that it is formatted exact way it we expect to be. The backend writer needs to be super careful while writing the code for some form of protection that helps in filtering out the data. Joi gives programmers the ability to create Javascript blueprints and ensure that the application will accept properly structured input."
            }
            link={
              "https://www.geeksforgeeks.org/crud-operations-and-file-upload-using-node-js-and-mongodb/"
            }
          />

          {/* ))} */}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Blogs, "app__blogs"),
  "blogs",
  "app__primarybg"
);
