import React from "react";
import { motion } from "framer-motion";

import "../../container/Skills/Skills.scss";

const SkillComponent = (props) => {
  return (
    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__skills-item app__flex"
      key={props.name}
    >
      <div className="app__flex" style={{ backgroundColor: "#edf2f8" }}>
        <img src={props.img} alt={props.name} />
      </div>
      <p className="p-text">{props.name}</p>
    </motion.div>
  );
};

export default SkillComponent;
