import React from "react";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { FaMediumM } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a
        href="https://www.linkedin.com/in/arnav-mah"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <BsLinkedin />
      </a>
      <a
        href="https://medium.com/@Arnav-mah"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <FaMediumM />
      </a>
      <a
        href="https://github.com/ArnavMahajan01"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <BsGithub />
      </a>
    </div>
  );
};

export default SocialMedia;
